import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import NewsList from "../components/NewsList"

import * as inlineStyles from "../components/Layout/inlineStyles"

const Press = props => {
  const posts = props.data.allWordpressPost.edges.map(
    postItem => postItem.node
  )

  return (
  <Layout>
    <SEO title="Press" />
    <div
      className="container"
      style={{
        ...inlineStyles.singleItemContainer,
        fontSize: undefined,
        lineHeight: undefined,
      }}
    >
      <div style={{ fontSize: `1.125em`, lineHeight: `1.75` }}>
        <h1>Press</h1>
      </div>
      <NewsList posts={posts}/>
    </div>
  </Layout>
)}

export default Press

export const postQuery = graphql`
  {
    allWordpressPost(sort: { fields: date, order: DESC }, limit: 12) {
      edges {
        node {
          id
          slug
          title
          date
          excerpt
          content
          date
          acf {
            featuredImage {
              altText: alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 384, maxHeight: 384) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
