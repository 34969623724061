import React from "react"

import NewsListItem from "../NewsListItem"

import styles from "./styles.module.css"

const NewsList = ({ posts }) => (
  <ul className={styles.newsList}>
    {posts.map(postItem => (
      <NewsListItem
        key={postItem.id}
        title={postItem.title}
        slug={`/press/${postItem.slug}`}
        excerpt={postItem.excerpt}
        featuredImage={postItem.acf ? postItem.acf.featuredImage : null}
      />
    ))}
  </ul>
)

export default NewsList
