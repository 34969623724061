import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import sanitizeHtml from "sanitize-html"
import Img from "gatsby-image"

import styles from "./styles.module.css"

const NewsListItem = ({ title, excerpt, featuredImage, slug }) => (
  <li className={styles.newsListItem}>
    <Link to={`${slug}`}>
      <div className={styles.newsItem}>
        <div className={styles.featuredImageContainer}>
          {featuredImage ? (
            <Img
              className={styles.featuredImage}
              src={featuredImage.localFile.childImageSharp.src}
              fluid={featuredImage.localFile.childImageSharp.fluid}
              alt={featuredImage.altText}
            />
          ) : (
            <div className={styles.featuredImagePlaceholder} />
          )}
        </div>
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <div
            className={styles.excerpt}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(excerpt.replace(/\u2028/g, " ")),
            }}
          />
        </div>
      </div>
    </Link>
  </li>
)

export default NewsListItem

NewsListItem.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  featuredImage: PropTypes.any,
  slug: PropTypes.string.isRequired,
}
